import React from 'react'

export default function QrForMerchent() {
    return (
        <>
            <div>
                <h3 className='f_45 font_700 color_dark pb-2'>
                    Transactions and Fees
                </h3>


                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q9: What are the fees for using LycanPay?</h5>
                    <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'>The payment gateway transaction fee is 2.70% of the invoice amount. There might
                        be additional fees depending on your selected package and transaction volume

                    </p>
                </div>

                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q10: What are the withdrawal limits? </h5>
                    <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'> Withdrawal limits vary by package: $40,000 per day for the Free package,
                        $80,000 for Small Business, $150,000 for Medium Business, and $200,000 for Large
                        Business

                    </p>
                </div>
                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q11: How long does verification take? </h5>
                    <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'> Verification times depend on the package. It ranges from 24 hours for the Free
                        package to as fast as 4 hours for the Large Business package.
                    </p>
                </div>

            </div>
        </>
    )
}
