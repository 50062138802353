import React from 'react'

export default function Payment() {
    return (
        <>
            <div>
                <h3 className='f_45 font_700 color_dark pb-2'>
                    General Information
                </h3>
                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q1: What is LycanPay? </h5>
                    <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'> LycanPay is an all-in-one platform designed for managing crypto payments and
                        embedding fintech solutions into your business. It facilitates both in-store and online
                        payments, providing a seamless crypto payment experience.
                    </p>
                </div>

                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q2: How does LycanPay work?</h5>
                    <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'>LycanPay integrates with your business to enable cryptocurrency transactions.
                        Customers can pay using various cryptocurrencies using a single QR code, and
                        LycanPay ensures the funds are securely processed and transferred to your account.
                    </p>
                </div>

                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q3: Is LycanPay secure?</h5>
                    <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'>Yes, LycanPay employs advanced security measures, including encryption and twofactor authentication (2FA), to protect transactions and user information. Compliance
                        with industry standards is a top priority
                    </p>
                </div>


            </div>
        </>
    )
}
