
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";


export default function Footer() {
    return (
        <>
            <div className="main_container" >
                <div className="second_container">
                    <div className="main-footer">
                        <div className="row">
                            <div className="col-sm-4">
                                <Fade bottom>
                                    <div className="footer-img">
                                        <Link to={'/'}><img src="/assets/img/logo/white_logo.svg" /></Link>
                                    </div>
                                </Fade>

                                <div className="footer-desp">
                                    <p>LycanPay is an all-in-one platform for managing crypto payments, offering secure and
                                        seamless transactions for businesses and consumers. With features like easy account
                                        setup, API integration, and robust security measures, LycanPay simplifies crypto
                                        payments and connects you to the $2 trillion crypto market.</p>
                                </div>
                            </div>
                            <div className="col-xxl-6 offset-xxl-2 col-lg-7 offset-lg-1 col-sm-7">
                                <div className="row">
                                    <div className="col-md-3 col-6">
                                        <Fade bottom>
                                            <p className="item-header">Services </p>
                                            <ul className="items">
                                                <li> <Link to={'/'}>Merchant</Link></li>
                                                <li> <Link to={'/'}>Token Listing</Link></li>
                                                <li> <Link to={'/'}>Button Maker</Link></li>
                                                <li> <Link to={'/business'}>Business</Link></li>
                                            </ul>
                                        </Fade>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <Fade bottom>
                                            <p className="item-header">Docs</p>
                                            <ul className="items">
                                                <li> <Link to={'/'}>Onepager</Link></li>
                                                <li> <Link to={'/'}>Legal Docs</Link></li>
                                                <li> <Link to={'/'}>Whitepaper</Link></li>
                                                <li> <Link to={'/'}>PPT</Link></li>
                                            </ul>
                                        </Fade>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <Fade bottom>
                                            <p className="item-header">Company</p>
                                            <ul className="items">
                                                <li> <Link to={'/pricing'}>Pricing</Link></li>
                                                <li> <Link to={'/faqs'}>FAQ</Link></li>
                                                <li> <Link to={'/contact'}>Contact</Link></li>
                                            </ul>
                                        </Fade>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <Fade bottom>
                                            <p className="item-header">Legal</p>
                                            <ul className="items">
                                                <li> <Link to={'/'}>Terms of use</Link></li>
                                                <li> <Link to={'/'}>Privacy Notice</Link></li>
                                                <li> <Link to={'/'}>Cookie Policy</Link></li>
                                            </ul>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="second-footer">
                        <div className="row align-items-center justify-content-lg-between justify-content-start">
                            <div className="col-sm-6">
                                <Fade bottom>
                                    <div className="d-flex social-main justify-content-start mb-sm-0 mb-3">
                                        <Link className="footer-social" to={'/'}><img className="w-100" src="/assets/img/footer/social_media.svg" alt="social-media" /></Link>
                                        <Link className="footer-social" to={'/'}><img className="w-100" src="/assets/img/footer/social_media.svg" alt="social-media" /></Link>
                                        <Link className="footer-social" to={'/'}><img className="w-100" src="/assets/img/footer/social_media.svg" alt="social-media" /></Link>
                                        <Link className="footer-social" to={'/'}><img className="w-100" src="/assets/img/footer/social_media.svg" alt="social-media" /></Link>
                                        <Link className="footer-social" to={'/'}><img className="w-100" src="/assets/img/footer/social_media.svg" alt="social-media" /></Link>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-sm-6 ">
                                <Fade bottom>
                                    <p className="text-sm-end text-start mb-0 f_16 font_500">  @ 2022 - 2024 <Link to={'/'}> LycanPay. </Link> All right reserved.</p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}