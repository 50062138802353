import React from 'react'

export default function LearnTwo() {
  return (
      <>
          <div>
              <h3 className='f_45 font_700 color_dark pb-2'>
                  Fees
              </h3>
              <div>
                  <h5 className='f_20 mb-2 font_600 color_dark '>How may I use Lycan Pay?</h5>
                  <p className='f_18 color_dark font_500 opacity_50 border-bottom pb-3'>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete
                      account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one
                      rejects, dislikes, or avoids pleasure itself, because it is pleasure.
                  </p>
              </div>
          </div>
      </>
  )
}
