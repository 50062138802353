import Header from "../Commponet/Header";
import Get_start from "../Commponet/Get_start";
import Footer from "../Commponet/Footer";

import { Link } from 'react-router-dom'
import { useEffect, useRef, useState } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import { } from "react-gsap";
import { Fade, Slide, Zoom } from "react-reveal";
import Pulse from 'react-reveal/Pulse';
import $ from 'jquery';
// import SmoothScroll from "../Commponet/smoothscroll/SmoothScroll";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Scrollbar from "smooth-scrollbar";


export default function Pricing() {
    const [tab, setTab] = useState(0)
    const options = {
        loop: true,
        // stagePadding: 100,
        margin: 30,
        nav: true,
        navText: ['<img src="/assets/img/icon/left.svg" />', '<img src="/assets/img/icon/right.svg" />'],
        dots: false,
        autoplayHoverPause: true,
        navClass: ["owl-prev", "owl-next"],
        navSpeed: 1500,
        navElement: "div",
        navContainerClass: "slider_nav",
        lazyLoad: true,
        responsiveClass: true,
        autoplay: false,
        // center: true,
        autoplaySpeed: 1100,
        responsive: {
            0: {
                items: 1.5,
                slideBy: 1,
                margin: 15
            },
            600: {
                items: 1.5,
                slideBy: 1,
                margin: 15
            },
            768: {
                items: 2.5,
                slideBy: 1,
                margin: 20
            },
            900: {
                items: 3.5,
                slideBy: 1,
                margin: 15
            },
            1440: {
                items: 4.5,
                slideBy: 2
            },
            1920: {
                items: 5,
                slideBy: 2
            }
        }
    };
    const api = {
        loop: true,
        // stagePadding: 100,
        mouseDrag: false,
        margin: 30,
        nav: true,
        navText: ['<img src="/assets/img/icon/left.svg" />', '<img src="/assets/img/icon/right.svg" />'],
        dots: false,
        autoplayHoverPause: true,
        navClass: ["owl-prev", "owl-next"],
        navSpeed: 1500,
        navElement: "div",
        navContainerClass: "slider_nav",
        lazyLoad: true,
        responsiveClass: true,
        autoplay: false,
        // center: true,
        autoplaySpeed: 1100,
        responsive: {
            0: {
                items: 1,
                margin: 15
            },
            600: {
                items: 1,
                margin: 15
            },
            768: {
                items: 1.2,
                margin: 20
            },
            1440: {
                items: 1.5,
                slideBy: 1
            },
            1920: {
                items: 1.7,
                slideBy: 1
            }
        }
    };
    const faq = {
        margin: 40,
        nav: true,
        navText: ['<img src="/assets/img/icon/left.svg" />', '<img src="/assets/img/icon/right.svg" />'],
        navClass: ["owl-prev", "owl-next"],
        navSpeed: 1500,
        navElement: "div",
        navContainerClass: "slider_faq",
        lazyLoad: true,
        responsiveClass: true,
        autoplay: false,
        dots: false,
        // center: true,
        responsive: {
            0: {
                items: 1,
                slideBy: 1,
            },
            600: {
                items: 1,
                slideBy: 1,
                margin: 15
            },
            768: {
                items: 2,
                slideBy: 1,
                margin: 20
            },

            1920: {
                items: 2,
                slideBy: 1
            }
        }
    };

    //  progress-top with scroll script
    useEffect(() => {
        window.addEventListener('scroll', () => {
            document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
        }, false);
    }, [])

    const scroller = useRef();

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const scroller = document.querySelector(".scroller");
        const bodyScrollBar = Scrollbar.init(scroller);

        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        bodyScrollBar.addListener(ScrollTrigger.update);

        gsap.to(document.querySelector("#box"), {
            duration: 10,
            scrollTrigger: {
                trigger: document.querySelector("#box"),
                start: "top 10%",
                markers: false,
                scroller: scroller,
                pin: false
            }
        });

        // This part is only neccessary if you're using ScrollTrigger's markers for testing:

    }, []);


    return (
        <>
            <div>
                {/* <div className="progress"></div> */}
                {/* Header section start */}
                <header >
                    <Header />
                </header>
                {/* Header section END */}

                <div className="scroller">

                    {/* Hero section start */}
                    <section>
                        <div className="hero">
                            <div className="main_container position-relative">
                                <div className="row text-sm-start text-center" >
                                    <div className="col-md-9 col-sm-12">
                                        <Fade bottom>
                                            <h1><span className="first_heading ">We've got a pricing plan that’s perfect for your business</span></h1>
                                            <div>
                                                <a href="https://vendor.lycanpay.com/register" className="start_btn mb-sm-0 mb-5 ">Get started for Free </a>
                                                {/* <a href="#" className="scan_pay">Scan & Pay <img className="ms-2 tras-aerrow" src="assets/img/icon/errow.svg" alt="scan pay" /></a> */}
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Hero section END */}



                    <section>
                        <div className="main_container padd200 " >
                            <div>
                                <div className="bar-pro">
                                    <div className="bar-inner" style={{ width: `${tab === 0 ? "33" : tab === 1 ? "50" : tab === 2 ? "66" : "100"}% ` }}><span>
                                        <div className="bar-inv">
                                            {tab === 0 ? "100" : tab === 1 ? "1000" : tab === 2 ? "5000" : "15000"} invoices
                                        </div>
                                    </span></div>
                                </div>
                                <div className="bro-per">
                                    <p>0</p>
                                    <p>100</p>
                                    <p>5000</p>
                                    <p>15000</p>
                                </div>
                                <div className="fees-box">
                                    <div className={tab === 0 ? "fees-one fees-all active-pricing" : "fees-one fees-all"} onClick={() => setTab(0)}>
                                        <div className="f1-p">
                                            <p>Free</p>
                                        </div>
                                        <div className="fees-br" />
                                        <div className="fees-pri"><span>$0</span>Per month</div>
                                        <div className="fees-total">
                                            <p>Total Invoice</p>
                                            <h2>100 Per day</h2>
                                        </div>
                                        <div className="fees-br" />
                                        <div className="fees-det">
                                            <p className="light-p">Withdrawal limit per day</p>
                                            <p className="dark-p">$40000</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Amount receiving reward</p>
                                            <p className="dark-p">0.70%</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Currency</p>
                                            <p className="dark-p">
                                                All Selected (depend on vendor) <br />
                                                by default all currency
                                            </p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">KYC*</p>
                                            <p className="dark-p">Not Compulsory</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Verification Time</p>
                                            <p className="dark-p">48 Hours</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Payment Gateway transaction fee</p>
                                            <p className="dark-p">2.70% (extra on invoice amount)</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Withdrawal</p>
                                            <p className="dark-p">24 Hours</p>
                                        </div>
                                        <div className="fees-det">
                                            <div className="fees-tik">
                                                <p className="light-p">Verify badge</p>
                                                <p>✅</p>
                                            </div>
                                            <p className="dark-p">No</p>
                                        </div>
                                        <div className="fees-det" style={{ margin: 0 }}>
                                            <p className="light-p">Note</p>
                                            <p className="dark-p fix-p">
                                                The vendor can only generate a total of 100 Invoices using SDK
                                                and the Create-Invoice Feature.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={tab === 1 ? "fees-two fees-all active-pricing" : "fees-two fees-all"} onClick={() => setTab(1)}>
                                        <div className="f1-p">
                                            <p>Standard</p>
                                        </div>
                                        <div className="fees-br" />
                                        <div className="fees-pri"><span>$250</span>Per month</div>
                                        <div className="fees-total">
                                            <p>Total Invoice</p>
                                            <h2>1000 Per day</h2>
                                        </div>
                                        <div className="fees-br" />
                                        <div className="fees-det">
                                            <p className="light-p">Withdrawal limit per day</p>
                                            <p className="dark-p">$40000</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Amount receiving reward</p>
                                            <p className="dark-p">0.70%</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Currency</p>
                                            <p className="dark-p">
                                                All Selected (depend on vendor) <br />
                                                by default all currency
                                            </p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">KYC*</p>
                                            <p className="dark-p">Not Compulsory</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Verification Time</p>
                                            <p className="dark-p">24 Hours</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Payment Gateway transaction fee</p>
                                            <p className="dark-p">2.70% (extra on invoice amount)</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Withdrawal</p>
                                            <p className="dark-p">18 Hours</p>
                                        </div>
                                        <div className="fees-det">
                                            <div className="fees-tik">
                                                <p className="light-p">Verify badge</p>
                                                <p>✅</p>
                                            </div>
                                            <p className="dark-p">Yes</p>
                                        </div>
                                        <div className="fees-btn">Choose Plan</div>
                                    </div>
                                    <div className={tab === 2 ? "fees-thr fees-all active-pricing" : "fees-thr fees-all"} onClick={() => setTab(2)}>
                                        <div className="f1-p">
                                            <p>Premium</p>
                                        </div>
                                        <div className="fees-br" />
                                        <div className="fees-pri"><span>$500</span>Per month</div>
                                        <div className="fees-total">
                                            <p>Total Invoice</p>
                                            <h2>5000 Per day</h2>
                                        </div>
                                        <div className="fees-br" />
                                        <div className="fees-det">
                                            <p className="light-p">Withdrawal limit per day</p>
                                            <p className="dark-p">$80000</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Amount receiving reward</p>
                                            <p className="dark-p">0.70%</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Currency</p>
                                            <p className="dark-p">
                                                All Selected (depend on vendor) <br />
                                                by default all currency
                                            </p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">KYC*</p>
                                            <p className="dark-p">Not Compulsory</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Verification Time</p>
                                            <p className="dark-p">15 Hours</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Payment Gateway transaction fee</p>
                                            <p className="dark-p">2.70% (extra on invoice amount)</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Withdrawal</p>
                                            <p className="dark-p">12 Hours</p>
                                        </div>
                                        <div className="fees-det">
                                            <div className="fees-tik">
                                                <p className="light-p">Verify badge</p>
                                                <p>✅</p>
                                            </div>
                                            <p className="dark-p">Yes</p>
                                        </div>
                                        <div className="fees-btn">Choose Plan</div>
                                    </div>
                                    <div className={tab === 3 ? "fees-fou fees-all active-pricing" : "fees-fou fees-all"} onClick={() => setTab(3)}>
                                        <div className="f1-p">
                                            <p>Enterprise</p>
                                            <div className="fees-fir-btn">Recommanded</div>
                                        </div>
                                        <div className="fees-br" />
                                        <div className="fees-pri"><span>$1000</span>Per month</div>
                                        <div className="fees-total">
                                            <p>Total Invoice</p>
                                            <h2>15000 Per day</h2>
                                        </div>
                                        <div className="fees-br" />
                                        <div className="fees-det">
                                            <p className="light-p">Withdrawal limit per day</p>
                                            <p className="dark-p">$200000</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Amount receiving reward</p>
                                            <p className="dark-p">1.70%</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Currency</p>
                                            <p className="dark-p">
                                                All Selected (depend on vendor) <br />
                                                by default all currency
                                            </p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">KYC*</p>
                                            <p className="dark-p">Not Compulsory</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Verification Time</p>
                                            <p className="dark-p">12 Hours</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Payment Gateway transaction fee</p>
                                            <p className="dark-p">2.70% (extra on invoice amount)</p>
                                        </div>
                                        <div className="fees-det">
                                            <p className="light-p">Withdrawal</p>
                                            <p className="dark-p">4 Hours</p>
                                        </div>
                                        <div className="fees-det">
                                            <div className="fees-tik">
                                                <p className="light-p">Verify badge</p>
                                                <p>✅</p>
                                            </div>
                                            <p className="dark-p">Yes</p>
                                        </div>
                                        <div className="fees-btn">Choose Plan</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section >

                    {/* footer section and get start section start */}
                    < section >
                        {/* contect section start */}
                        <div div >
                            <Get_start />
                        </div >
                        {/* contect section end */}

                        {/* footer section start */}
                        <div className="footer-padd">
                            <Footer />
                        </div>
                        {/* footer section end */}
                    </section >
                    {/* footer section and get start section end */}
                </div >
            </div >

        </>
    );
}


