import React from 'react'

export default function Fees() {
  return (
    <>
      <div>
        <h3 className='f_45 font_700 color_dark pb-2'>
          Using LycanPay
        </h3>
        <div>
          <h5 className='f_20 mb-2 font_600 color_dark '>Q6: How do I generate an API key?</h5>
          <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'>You can generate an API key from your LycanPay dashboard. Navigate to the
            "Generate API" section, and follow the prompts to create and manage your API keys.
          </p>
        </div>
        <div>
          <h5 className='f_20 mb-2 font_600 color_dark '>Q7: How do I create an invoice? </h5>
          <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'>To create an invoice, log in to your LycanPay account and go to the "Create
            Invoice" section. You will need to provide the full name, amount, and email address of
            the payer
          </p>
        </div>
        <div>
          <h5 className='f_20 mb-2 font_600 color_dark '>Q8: How can I activate a package? </h5>
          <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'>You can activate a package by selecting one of the available plans (Small
            Business, Medium Business, Large Business) from your dashboard and following the
            activation steps. Each package has different features and limits.

          </p>
        </div>
      </div>
    </>
  )
}
