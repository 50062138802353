import React from 'react'

export default function Transaction() {
    return (
        <>
            <div>
                <h3 className='f_45 font_700 color_dark pb-2'>
                    Account Setup
                </h3>
                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q4: How do I create an account with LycanPay?</h5>
                    <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'>To create an account, visit our website and click "Sign Up." You will need to provide
                        your first name, last name, email address, company name, and mobile number. You
                        will also need to set a password and verify your email address.

                    </p>
                </div>

                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q5: What documents are required to set up an account? </h5>
                    <p className='f_16 color_dark_op50 font_500  border-bottom pb-3'>To complete the KYC (Know Your Customer) process, you will need to provide
                        identification documents as per regulatory requirements. This is mandatory for
                        activating certain packages and withdrawal limits.

                    </p>
                </div>

                
            </div>

        </>
    )
}
