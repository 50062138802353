import React, { useEffect, useRef, useState } from 'react'

import Header from '../Commponet/Header';
import Payment from '../Commponet/Payment';
import Transaction from '../Commponet/Transaction';
import Fees from '../Commponet/Fees';
import QrForMerchent from '../Commponet/QrForMerchent';
import LearnOne from '../Commponet/LearnOne';
import LearnTwo from '../Commponet/LearnTwo';
// import SmoothScroll from "../Commponet/smoothscroll/SmoothScroll";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Scrollbar from "smooth-scrollbar";
import Get_start from '../Commponet/Get_start';
import Footer from '../Commponet/Footer';



export default function Faqs() {


    const [open, setOpen] = useState(true);
    const handlerevent = () => {
        setOpen(!open)
    }

    const scroller = useRef();

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const scroller = document.querySelector(".scroller");
        const bodyScrollBar = Scrollbar.init(scroller);

        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        bodyScrollBar.addListener(ScrollTrigger.update);

        gsap.to(document.querySelector("#box"), {
            duration: 10,
            scrollTrigger: {
                trigger: document.querySelector("#box"),
                start: "top 10%",
                markers: false,
                scroller: scroller,
                pin: false
            }
        });

        // This part is only neccessary if you're using ScrollTrigger's markers for testing:

    }, []);


    return (
        <>


            <div className="scroller" >
                {/* Header section start */}
                <header>
                    <Header />
                </header>
                {/* Header section END */}

                {/* faq section start */}
                <div>
                    <div className='d-flex faq'>
                        <div className={open ? "sidebar" : "sidebar left_minus"}>
                            <div className="main_container pe-0 ">
                                <div className='position-relative'>
                                    <h2 className="heding_body color_dark title">
                                        FAQs
                                    </h2>

                                    <div className='position-relative'>
                                        <input type="email" className="form-control search_control" placeholder="Search the docs.." />
                                        <span><img className="serch_img" src="/assets/img/faq/search.svg" alt="search" /></span>
                                    </div>

                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item border-0">
                                            <h6 className="accordion-header" id="headingOne">
                                                <button className="accordion-button btn_font" type="button" >
                                                    FAQs
                                                </button>
                                            </h6>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body faq_body">
                                                    <div>
                                                        <div className="nav-pills nav d-block" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                            <button className="nav-link active" id="payment-tab" data-bs-toggle="pill" data-bs-target="#payment" type="button" role="tab" aria-controls="payment" aria-selected="true">General Information</button>
                                                            <button className="nav-link" id="transaction-tab" data-bs-toggle="pill" data-bs-target="#transaction" type="button" role="tab" aria-controls="transaction" aria-selected="false">Account Setup</button>
                                                            <button className="nav-link" id="fees-tab" data-bs-toggle="pill" data-bs-target="#fees" type="button" role="tab" aria-controls="fees" aria-selected="false">Using LycanPay</button>
                                                            <button className="nav-link" id="qr-tab" data-bs-toggle="pill" data-bs-target="#qr" type="button" role="tab" aria-controls="qr" aria-selected="false">Transactions and Fees</button>
                                                            <button className="nav-link" id="support-tab" data-bs-toggle="pill" data-bs-target="#support" type="button" role="tab" aria-controls="support" aria-selected="false">Support and Troubleshooting</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="accordion-item border-0">
                                            <h6 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button btn_font collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Learn
                                                </button>
                                            </h6>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div>
                                                    <div className="nav-pills nav d-block" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                        <button className="nav-link" id="learnone-tab" data-bs-toggle="pill" data-bs-target="#learnone" type="button" role="tab" aria-controls="learnone" aria-selected="false">Learn</button>
                                                        <button className="nav-link" id="learntwo-tab" data-bs-toggle="pill" data-bs-target="#learntwo" type="button" role="tab" aria-controls="learntwo" aria-selected="false">LearnTwo</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item border-0">
                                            <h6 className="accordion-header" id="headingThree">
                                                <button className="accordion-button btn_font collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    About
                                                </button>
                                            </h6>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body faq_body">
                                                    <a className="active" href="#home">Payment</a>
                                                    <a href="#news">Transaction</a>
                                                    <a href="#contact">Fees</a>
                                                    <a href="#about">QR for merchant</a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>

                                    <button className={open ? "toggle_btn toggle_left_faq" : "toggle_btn toggle_right_faq"} onClick={handlerevent}></button>
                                </div>
                            </div>

                        </div>

                        <div className={open ? "content" : "content w-100 ms-0"}>
                            <div className="main_container pe-0">
                                <div className="tab-content position-relative" id="v-pills-tabContent">
                                    <button className={open ? "toggle_btn toggle_left" : "toggle_btn toggle_right"} onClick={handlerevent}></button>
                                    <div className="tab-pane fade show active" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                                        <Payment />
                                    </div>
                                    <div className="tab-pane fade" id="transaction" role="tabpanel" aria-labelledby="transaction-tab">
                                        <Transaction />
                                    </div>

                                    <div className="tab-pane fade" id="fees" role="tabpanel" aria-labelledby="fees-tab">
                                        <Fees />
                                    </div>

                                    <div className="tab-pane fade" id="qr" role="tabpanel" aria-labelledby="qr-tab">
                                        <QrForMerchent />
                                    </div>

                                    <div className="tab-pane fade" id="support" role="tabpanel" aria-labelledby="support-tab">
                                        <LearnOne />
                                    </div>

                                    <div className="tab-pane fade" id="learntwo" role="tabpanel" aria-labelledby="learntwo-tab">
                                        <LearnTwo />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* faq section end */}

                {/* footer section and get start section start */}
                <section>
                    {/* contect section start */}
                    <div>
                        <Get_start />
                    </div>
                    {/* contect section end */}

                    {/* footer section start */}
                    <div className="footer-padd">
                        <Footer />
                    </div>
                    {/* footer section end */}
                </section>
                {/* footer section and get start section end */}
            </div>


        </>
    )
}
