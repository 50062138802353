import Header from "../Commponet/Header";
import Get_start from "../Commponet/Get_start";
import Footer from "../Commponet/Footer";

import { Link } from 'react-router-dom'
import { useEffect, useRef } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import { } from "react-gsap";
import { Fade, Slide, Zoom } from "react-reveal";
import Pulse from 'react-reveal/Pulse';
import $ from 'jquery';
// import SmoothScroll from "../Commponet/smoothscroll/SmoothScroll";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Scrollbar from "smooth-scrollbar";


export default function Home() {




    const options = {
        loop: true,
        // stagePadding: 100,
        margin: 30,
        nav: true,
        navText: ['<img src="/assets/img/icon/left.svg" />', '<img src="/assets/img/icon/right.svg" />'],
        dots: false,
        autoplayHoverPause: true,
        navClass: ["owl-prev", "owl-next"],
        navSpeed: 1500,
        navElement: "div",
        navContainerClass: "slider_nav",
        lazyLoad: true,
        responsiveClass: true,
        autoplay: false,
        // center: true,
        autoplaySpeed: 1100,
        responsive: {
            0: {
                items: 1.5,
                slideBy: 1,
                margin: 15
            },
            600: {
                items: 1.5,
                slideBy: 1,
                margin: 15
            },
            768: {
                items: 2.5,
                slideBy: 1,
                margin: 20
            },
            900: {
                items: 3.5,
                slideBy: 1,
                margin: 15
            },
            1440: {
                items: 4.5,
                slideBy: 2
            },
            1920: {
                items: 5,
                slideBy: 2
            }
        }
    };

    const api = {
        loop: true,
        // stagePadding: 100,
        mouseDrag: false,
        margin: 30,
        nav: true,
        navText: ['<img src="/assets/img/icon/left.svg" />', '<img src="/assets/img/icon/right.svg" />'],
        dots: false,
        autoplayHoverPause: true,
        navClass: ["owl-prev", "owl-next"],
        navSpeed: 1500,
        navElement: "div",
        navContainerClass: "slider_nav",
        lazyLoad: true,
        responsiveClass: true,
        autoplay: false,
        // center: true,
        autoplaySpeed: 1100,
        responsive: {
            0: {
                items: 1,
                margin: 15
            },
            600: {
                items: 1,
                margin: 15
            },
            768: {
                items: 1.2,
                margin: 20
            },
            1440: {
                items: 1.5,
                slideBy: 1
            },
            1920: {
                items: 1.7,
                slideBy: 1
            }
        }
    };


    const faq = {
        margin: 40,
        nav: true,
        navText: ['<img src="/assets/img/icon/left.svg" />', '<img src="/assets/img/icon/right.svg" />'],
        navClass: ["owl-prev", "owl-next"],
        navSpeed: 1500,
        navElement: "div",
        navContainerClass: "slider_faq",
        lazyLoad: true,
        responsiveClass: true,
        autoplay: false,
        dots: false,
        // center: true,
        responsive: {
            0: {
                items: 1,
                slideBy: 1,
            },
            600: {
                items: 1,
                slideBy: 1,
                margin: 15
            },
            768: {
                items: 2,
                slideBy: 1,
                margin: 20
            },

            1920: {
                items: 2,
                slideBy: 1
            }
        }
    };


    //  progress-top with scroll script
    useEffect(() => {
        window.addEventListener('scroll', () => {
            document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
        }, false);
    }, [])

    const scroller = useRef();

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const scroller = document.querySelector(".scroller");
        const bodyScrollBar = Scrollbar.init(scroller);

        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        bodyScrollBar.addListener(ScrollTrigger.update);

        gsap.to(document.querySelector("#box"), {
            duration: 10,
            scrollTrigger: {
                trigger: document.querySelector("#box"),
                start: "top 10%",
                markers: false,
                scroller: scroller,
                pin: false
            }
        });

        // This part is only neccessary if you're using ScrollTrigger's markers for testing:

    }, []);


    return (
        <>
            <div>
                {/* <div className="progress"></div> */}
                {/* Header section start */}
                <header >
                    <Header />
                </header>
                {/* Header section END */}

                <div className="scroller">

                    {/* Hero section start */}
                    <section>
                        <div className="hero">
                            <div className="main_container position-relative">
                                <div className="row text-sm-start text-center" >
                                    <div className="col-md-6 col-sm-8">
                                        <Fade bottom>
                                            <h1><span className="first_heading ">Make your business crypto-friendly</span></h1>

                                            <p className="small_heding ">
                                                Easy integration of multiple <br className="d-none d-sm-block d-md-none" /> cryptocurrencies to <br className="d-lg-block d-none" /> grow <br className="d-none d-sm-block d-md-none" /> business globally.
                                            </p>
                                            <div>
                                                <a href="https://vendor.lycanpay.com/register" className="start_btn mb-sm-0 mb-5 ">Let’s integrate </a>
                                                {/* <a href="#" className="scan_pay">Scan & Pay <img className="ms-2 tras-aerrow" src="assets/img/icon/errow.svg" alt="scan pay" /></a> */}
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                                <div className="is-inview" >
                                    <div className="hero_img img2">
                                        <img src="assets/img/hero/card_b_2.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img3">
                                        <img src="assets/img/hero/card_b_3.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img4">
                                        <img src="assets/img/hero/card_b_4.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img5">
                                        <img src="assets/img/hero/card_b_5.png" alt="lock" className="w-100 left_card" />
                                    </div>
                                    <div className="hero_img img6">
                                        <img src="assets/img/hero/card_b_6.png" alt="lock" className="w-100 right_card" />
                                    </div>
                                    <div className="hero_img img7">
                                        <img src="assets/img/hero/tether.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img8">
                                        <img src="assets/img/hero/btc.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img9">
                                        <img src="assets/img/hero/eth.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img10 ">
                                        <img src="assets/img/hero/card_top.svg" alt="lock" className="w-100 right_card" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Hero section END */}

                    {/* Demo section start */}
                    <section className="watch_demo position-relative">
                        <div className="main_container position-relative" >
                            <div className="demo row">
                                <div className="col-xxl-4 col-lg-5 col-sm-6">
                                    <img src="assets/img/support/lock.svg" alt="lock" className="mb-3" />
                                    <h2 className="heding_demo mb-3" >Secure and easy-to-use crypto
                                        payment receiver for various businesses.
                                    </h2>
                                    <a href="#" className="demobtn dcf">Watch Demo <img className="ms-2 tras-aerrow" src="assets/img/icon/errow_blue.svg" alt="scan pay" /></a>
                                </div>
                            </div>
                        </div>

                        <div className="hero_img img1">
                            <img src="assets/img/hero/card_b_1.png" alt="lock" className="w-100 h-100" />
                        </div>
                    </section>
                    {/* Demo section END */}

                    {/* spport section start */}
                    <section>
                        <div className="support_sec">
                            <div className="main_container" >
                                <div className="row align-items-center justify-content-lg-between justify-content-center">
                                    <div className="col-xxl-auto col-md-4 col-12 mb-xxl-0 mb-md-3 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/support.svg" alt="lock" /> 24x7 Support</h6>                                </div>

                                    <div className="col-xxl-auto col-md-5 col-12 mb-xxl-0 mb-md-3 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/display.svg" alt="lock" /> Borderless</h6>
                                    </div>

                                    <div className="col-xxl-auto col-md-3 col-12 mb-xxl-0 mb-md-3 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/pointer.svg" alt="lock" /> User Friendly</h6>
                                    </div>

                                    <div className="col-xxl-auto col-md-4 col-12 mb-xxl-0 mb-md-0 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/secure.svg" alt="lock" /> Truly Secure</h6>
                                    </div>

                                    <div className="col-xxl-auto col-md-5 col-12 mb-xxl-0 mb-md-0 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/button.svg" alt="lock" /> Customized Payment Button</h6>
                                    </div>

                                    <div className="col-xxl-auto col-md-3 col-12 mb-xxl-0 mb-md-0 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/invoice.svg" alt="lock" /> Invoicing</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* support section end */}

                    {/* universe technology section start */}
                    <section >
                        <div className="bussiness_tool padd150 bg_light">
                            <div className="main_container">
                                <div className="second_container">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-lg-8" >
                                            <Slide top>
                                                <h2 className="heding_body color_dark title bottom">
                                                    Universal technology for<br className="d-none d-md-block" />faster payments
                                                </h2>
                                                <p className="text_dark f_18 font_500 bottom">LycanPay empowers your businesses with the global financial infrastructure of the future with easy integration Whether you're an established business or exploring new possibilities.</p>
                                            </Slide>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* universe technology section END */}

                    {/* crypto section Start */}
                    <section>
                        <div>
                            <Pulse>
                                <div className="position-relative z_index_1">
                                    <img src="assets/img/bussiness_tool.svg" className="w-100 d-none d-sm-block" alt="lock" />
                                    <img src="assets/img/bussiness_tool_mobile.png" className="w-100 d-block d-sm-none pt-5" alt="lock" />
                                </div>
                            </Pulse>

                        </div>
                        {/* Seamless crypto section start */}
                        <div>
                            <div className="bussiness_tool pb_150 pt_100 bg_yellow" >
                                <div className="main_container">
                                    <div className="second_container">
                                        <div className="row justify-content-center text-center">
                                            <Slide className="col-xxl-5 col-xl-8" top>
                                                <h3 className="heding_secondary color_extra_dark">Effortless and Borderless
                                                    crypto payments</h3>
                                                <p className="text_dark f_18 font_500">A simple crypto to crypto payment gateway allowing you to
                                                    pay and accept payments easily.
                                                </p>
                                                <div className="video_btn">
                                                    <img src="assets/img/video.svg" className="wh_50" alt="video" />
                                                    <p className="mb-0"> Find out how we started, and what we are currently up to. <span>
                                                        <Link className="" to={'/'}>Watch video</Link>
                                                    </span></p>
                                                </div>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Seamless crypto section END */}
                    </section>
                    {/* crypto section END */}

                    {/* Platform services section Start */}
                    <section>
                        <div className="container-fluid">
                            <div className="padd200 pb_200 overflow-hidden" >
                                <div className="main_container">
                                    <div className="second_container">
                                        <Slide left>
                                            <h2 className="heding_body color_dark text-sm-start text-center" >Platform services<br className="d-block" /> by LycanPay</h2>
                                        </Slide>

                                    </div>
                                </div>
                                <div className="main_container pe-0">
                                    <div className="slider_container">
                                        <OwlCarousel className="owl-theme" {...options}>

                                            <div className="card border-0 radius_15 overflow-hidden h_380">
                                                <img src="assets/img/platform_slide/slider-01.svg" className="card-img-top" alt="e-commerce" />
                                                <div className="card-body">
                                                    <h5 className="card-title">E-commerce</h5>
                                                    <p className="card-text font_500 color_dark f_16">Faster Payment processor with multiple payment options, chargeback prevention, and fraud management. </p>
                                                    {/* <a href="#" className="demobtn border-bottom-0">Learn More <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a> */}
                                                </div>
                                            </div>

                                            <div className="card border-0 radius_15 overflow-hidden h_380">
                                                <img src="assets/img/platform_slide/slider-02.svg" className="card-img-top" alt="e-commerce" />
                                                <div className="card-body">
                                                    <h5 className="card-title">ICO</h5>
                                                    <p className="card-text font_500 color_dark f_16">Quick payment receiver during ICO with flexible and diverse payment choices. </p>
                                                    {/* <a href="#" className="demobtn border-bottom-0">Learn More <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a> */}
                                                </div>
                                            </div>

                                            <div className="card border-0 radius_15 overflow-hidden h_380">
                                                <img src="assets/img/platform_slide/slider-03.svg" className="card-img-top" alt="e-commerce" />
                                                <div className="card-body">
                                                    <h5 className="card-title">NFT selling</h5>
                                                    <p className="card-text font_500 color_dark f_16">Connect with a unique community of customers in the crypto payment gateway world and receive your payment of the sold NFTs. </p>
                                                    {/* <a href="#" className="demobtn border-bottom-0">Learn More <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a> */}
                                                </div>
                                            </div>

                                            <div className="card border-0 radius_15 overflow-hidden h_380">
                                                <img src="assets/img/platform_slide/slider-04.svg" className="card-img-top" alt="e-commerce" />
                                                <div className="card-body">
                                                    <h5 className="card-title">Local shops</h5>
                                                    <p className="card-text font_500 color_dark f_16">Merchants can collect their payments in stores using mobile devices or POS machines. </p>
                                                    {/* <a href="#" className="demobtn border-bottom-0">Learn More <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a> */}
                                                </div>
                                            </div>

                                            <div className="card border-0 radius_15 overflow-hidden h_380">
                                                <img src="assets/img/platform_slide/slider-05.svg" className="card-img-top" alt="e-commerce" />
                                                <div className="card-body">
                                                    <h5 className="card-title">Digital goods</h5>
                                                    <p className="card-text font_500 color_dark f_16">We offer 13+ payment methods for customers to buy digital goods or make in-app purchases.</p>
                                                    {/* <a href="#" className="demobtn border-bottom-0">Learn More <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a> */}
                                                </div>
                                            </div>

                                            <div className="card border-0 radius_15 overflow-hidden h_380">
                                                <img src="assets/img/platform_slide/slider-04.svg" className="card-img-top" alt="e-commerce" />
                                                <div className="card-body">
                                                    <h5 className="card-title">P2P</h5>
                                                    <p className="card-text font_500 color_dark f_16">In P2P transactions, a buyer can directly transfer funds to the seller with speed and security just by having the account details of the merchant.</p>
                                                    {/* <a href="#" className="demobtn border-bottom-0">Learn More <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a> */}
                                                </div>
                                            </div>

                                            <div className="card border-0 radius_15 overflow-hidden h_380">
                                                <img src="assets/img/platform_slide/slider-07.svg" className="card-img-top" alt="e-commerce" />
                                                <div className="card-body">
                                                    <h5 className="card-title">Invoicing</h5>
                                                    <p className="card-text font_500 color_dark f_16">The use of a blockchain-based invoicing system will enable seamless payments from customers to a business's digital wallet. </p>
                                                    {/* <a href="#" className="demobtn border-bottom-0">Learn More <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a> */}
                                                </div>
                                            </div>
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Platform services section END */}

                    {/* Grow with LycanPay section Start */}
                    <section>
                        <div className="main_container">
                            <div className="grow">
                                <div className="second_container">
                                    <div className="row text-center justify-content-center mb-100">
                                        <Fade top>
                                            <div className="col-lg-6 col-sm-10" >
                                                <h2 className="heding_body color_dark mb-3 ">Grow with LycanPay</h2>
                                                <p className="text_dark f_18 font_500">LycanPay does not only help you to accept payments,
                                                    we are here to open your business up to the world.</p>
                                            </div>
                                        </Fade>

                                    </div>

                                    <div className="row">
                                        <div className="col-lg-3 col-sm-6 mb-lg-0 mb-4 text-center grow_feture">
                                            <Fade bottom>
                                                <img className="mb-4" src="assets/img/icon/convenient.svg" alt="convenice" />
                                                <h3 className="grow_font color_dark">Convenient</h3>
                                                <p className="text_dark mb-0 f_16 font_500">LycanPay provides advanced trouble-free
                                                    features like payment buttons, a hosted checkout, and embeddable invoices for your website.</p>
                                            </Fade>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 mb-lg-0 mb-4 text-center grow_feture">
                                            <Fade bottom>
                                                <img className="mb-4" src="assets/img/icon/pay.svg" alt="convenice" />
                                                <h3 className="grow_font color_dark">Plug and Pay</h3>
                                                <p className="text_dark mb-0 f_16 font_500">A deposit procedure in Crypto Payment
                                                    gateway is totally based on recurring seamless top-ups with a single sign-in.</p>
                                            </Fade>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 text-center grow_feture mb-sm-0 mb-4">
                                            <Fade bottom>
                                                <img className="mb-4" src="assets/img/icon/storage.svg" alt="convenice" />
                                                <h3 className="grow_font color_dark">Cold storage</h3>
                                                <p className="text_dark mb-0 f_16 font_500">The enormous part of cryptocurrencies are
                                                    captured in secure cold storage with such ease.</p>
                                            </Fade>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 text-center grow_feture">
                                            <Fade bottom>
                                                <img className="mb-4" src="assets/img/icon/bussines.svg" alt="convenice" />
                                                <h3 className="grow_font color_dark">Solid business relations</h3>
                                                <p className="text_dark mb-0 f_16 font_500">Establish solid business relations worldwide with the help of digital assets and advanced payment processors. </p>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Grow with LycanPay section END */}

                    {/* Quick Crypto In-store section Start */}
                    <section>
                        <div className="main_container padd200 d-sm-block d-none" >
                            <div className="second_container">
                                <div className="row align-items-center">
                                    <div className="col-lg-4 col-md-6 order-lg-1 order-md-2 mb-md-0 mb-5">
                                        <span className="scan_link">Scan 2 Pay</span>
                                        <h2 className="heding_body color_dark">Quick Crypto <br className="d-none d-lg-block" />
                                            In-store
                                        </h2>
                                        <p className="text_dark f_18">The most rapid way to pay in-store and online, whenever and wherever you like. This is built for you to make your crypto payment journey easier as LycanPay makes payments fast with innovative Features.</p>
                                        <a href="https://vendor.lycanpay.com/register" className="demobtn">Try Now <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a>
                                    </div>
                                    <div className="col-lg-7 col-md-6 offset-lg-1 order-lg-2 order-md-1 store">
                                        <Fade right>
                                            <img src="assets/img/store.svg" alt="store" className="scale-up-ver-top" />
                                        </Fade>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Quick Crypto In-store section END */}

                    {/* Quick Crypto In-store mobile view section Start */}
                    <section>
                        <div className="main_container padd200 d-sm-none d-block" >
                            <div className="second_container">
                                <div className="row" >
                                    <div className="col-lg-4 col-md-6 order-lg-1 order-md-2 mb-md-0 mb-5">
                                        <span className="scan_link">Scan 2 Pay</span>
                                        <h2 className="heding_body color_dark">Quick Crypto <br className="d-none d-lg-block" />
                                            In-store</h2>

                                        <p className="text_dark f_18">The most rapid way to pay in-store and online, whenever and wherever you like. This is built for you to make your crypto payment journey easier as LycanPay makes payments fast with innovative Features.</p>
                                        <a href="#" className="demobtn">Try Now <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a>
                                    </div>
                                    <div className="col-lg-7 col-md-6 offset-lg-1 order-lg-2 order-md-1 store">
                                        <img src="assets/img/store.svg" alt="store" className="scale-up-ver-top" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Quick Crypto In-store mobile view section END */}

                    {/* Multiple Gateway section start */}
                    <section>
                        <div className="padd200">
                            <div className="multigateway">
                                <div className="main_container">
                                    <div className="second_container">
                                        <div className="row ">
                                            <div className="col-lg-5 col-sm-5 coins_suppor">
                                                <img className="mb-4 wh_35" src="assets/img/icon/dark_round.svg" alt="round" />
                                                <Pulse>
                                                    <h2 className="heding_body">Multiple Gateway</h2>
                                                </Pulse>
                                                <p className="f_18 font_500">Allow your users to pay in whatever popular currency they like.</p>
                                                <p className="text__blue f_18 mb-2 font_500">Coins Supported</p>
                                                <Pulse>
                                                    <div className="d-flex justify-content-sm-start justify-content-center">
                                                        <img src="assets/img/coin/btc.svg" className="me-lg-2 me-1 wh_35" />
                                                        <img src="assets/img/coin/eth.svg" className="me-lg-2 me-1 wh_35" />
                                                        <img src="assets/img/coin/trx.svg" className="me-lg-2 me-1 wh_35" />
                                                        <img src="assets/img/coin/ripale.svg" className="me-lg-2 me-1 wh_35" />
                                                        <img src="assets/img/coin/d.svg" className="me-lg-2 me-1 wh_35" />
                                                        <img src="assets/img/coin/tither.svg" className="me-lg-2 me-1 wh_35" />
                                                        <img src="assets/img/coin/bnb.svg" className="me-lg-2 me-1 wh_35" />
                                                        <a href="javascript:void(0);" className="more_btn" >MORE..</a>
                                                    </div>
                                                </Pulse>
                                            </div>
                                            <div className="col-sm-5 offset-sm-2">
                                                <img className="mb-4 wh_35" src="assets/img/icon/hend.svg" alt="round" />
                                                <Pulse>
                                                    <h2 className="heding_body text-white">Multiple Payout</h2>
                                                </Pulse>
                                                <p className="text-white f_18 font_200">Choose whatever popular currency you want to get paid in.</p>
                                                <p className="text-white f_18 mb-2 font_500">Choose Your Currencies</p>
                                                <Pulse>
                                                    <div className="d-flex justify-content-sm-start justify-content-center">
                                                        <img src="assets/img/coin/doller.svg" className="me-2 wh_35" />
                                                        <img src="assets/img/coin/uroap.svg" className="me-2 wh_35" />
                                                        <img src="assets/img/coin/pound.svg" className="me-2 wh_35" />
                                                        <img src="assets/img/coin/rupees.svg" className="me-2 wh_35" />
                                                        <a href="javascript:void(0);" className="more_btn" >MORE..</a>
                                                    </div>
                                                </Pulse>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="swap_img">
                                    <img src="assets/img/icon/swap.svg" alt="sample" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Multiple Gateway section END */}

                    {/* fetures with LycanPay section Start */}
                    <section>
                        <div className="padd200">
                            <div className="main_container">
                                <div className="">
                                    <div className="second_container">
                                        <div className="row text-center justify-content-center pb_70">
                                            <div className="col-lg-6">
                                                <Fade top>
                                                    <h2 className="heding_body color_dark mb-3 ">What makes LycanPay unique (Key Features) </h2>
                                                    <p className="text_dark f_18 font_500">Our framework designers and UX experts are always actively trying to make life easier
                                                        for merchants and their users.
                                                        {/* <br className="d-none d-md-block" /> */}
                                                        to make payments easy and secure.</p>
                                                </Fade>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xxl-3 col-lg-6 col-sm-6 order-xxl-1 order-1">
                                                <div className="card fetures">
                                                    <div className="card_yellow_border">
                                                        <div className="feture_card ">
                                                            <h6>
                                                                Create new quick invoice
                                                            </h6>
                                                            <div className="mb-xxl-3 mb-2">
                                                                <label className="form-label">Customer name</label>
                                                                <input type="email" className="form-control" placeholder="" />
                                                            </div>
                                                            <div className="mb-xxl-3 mb-xl-0 mb-0">
                                                                <label className="form-label">Customer email address</label>
                                                                <input type="email" className="form-control" placeholder="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card_bottom_part">
                                                        <h4 className="font_700 text__blue mb-0">Invoice generator</h4>
                                                        <p className="color_dark font_500">Simple, secure, and single-clicked transaction method which is easy to track and monitor.</p>
                                                        <div className="card_biiner d-flex align-items-center">
                                                            <a className="font_500" href="javascript:void(0);">Try it now, it's free</a>
                                                            <img className="ms-2" src="/assets/img/feature/right-arrow.png" alt="right-arrow" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-lg-12 col-sm-12 order-xxl-2 order-3">
                                                <div className="main_card_center card fetures">
                                                    <div className="row align-items-end">
                                                        <div className="col-lg-5 col-sm-6">
                                                            <div className="card_center">
                                                                <h5 className="font_600">Recent transactions</h5>
                                                                <div className="card_cinner text-center">
                                                                    <Pulse>
                                                                        <img className="" src="/assets/img/feature/woo.png" alt="woo" />
                                                                    </Pulse>
                                                                    <h5 className="font_600 color_dark">Payout from <span>Woocommerce</span></h5>
                                                                    <p className="font_400">Plugin Installation</p>
                                                                </div>
                                                                <div className="card_cinner text-center">
                                                                    <Pulse>
                                                                        <img className="" src="/assets/img/feature/shopify.png" alt="shopify" />
                                                                    </Pulse>
                                                                    <h5 className="font_600 color_dark">Payout from <span className="color_green">Shopify</span></h5>
                                                                    <p className="font_400">API Integration</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7 col-sm-6">
                                                            <div className="card_inner_right">
                                                                <h2 className="font_600 color_dark">49+</h2>
                                                                <h5 className="font_500 color_dark">Integrated platform</h5>
                                                                <div className="cir_border"></div>
                                                                <h4 className="font_600 color_dark">e-Commerce <br className="d-xxl-block d-none" /> CMS Integration</h4>
                                                                <p className="font_500 color_dark mb-0">20+ languages are supported
                                                                    Users can Integrate LycanPay into any e-commerce site to receive the payments worldwide. </p>
                                                                <div className="card_biiner d-flex align-items-center">
                                                                    <a className="font_500" href="javascript:void(0);">Learn more</a>
                                                                    <img className="ms-2" src="/assets/img/feature/right-arrow.png" alt="right-arrow" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-3 col-lg-6 col-sm-6 order-xxl-3 order-2">
                                                <div className="card fetures">
                                                    <div className="card_yellow_border cyb_yellow">
                                                        <div className="feture_card text-center radius_6">
                                                            <Pulse>
                                                                <img className="wh_75 mb-2" src="/assets/img/feature/transaction.png" alt="transaction" />
                                                            </Pulse>
                                                            <h4 class="font_700 text__blue mb-0">120 transactions</h4>
                                                            <p className="color_dark font_500 opacity_50 mt-1 mb-xxl-2 mb-1">Outlet 2 - London</p>
                                                            <Link className="color_dark" to={'/'}>See all outlet</Link>
                                                        </div>
                                                    </div>
                                                    <div className="card_bottom_part">
                                                        <h4 className="font_700 text__blue mb-1">In-Store <br className="d-xxl-block d-none" /> Payments</h4>
                                                        <p className="color_dark font_500">Users can accept payment from any Offline store within seconds with LycanPay’s in-store payment technique. </p>
                                                        <div className="card_biiner d-flex align-items-center">
                                                            <a className="font_500" href="javascript:void(0);">Try it now, it's free</a>
                                                            <img className="ms-2" src="/assets/img/feature/right-arrow.png" alt="right-arrow" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="card fetures p_20">
                                                <div className="row">
                                                    <div className="col-sm-4 mb-sm-0 mb-4">
                                                        <div className="d-flex align-items-center card_bottom_part p-0">
                                                            <Pulse>
                                                                <img className="wh_75" src="/assets/img/feature/user.svg" alt="transaction" />
                                                            </Pulse>
                                                            <h4 className="font_700 text__blue mb-0 border_side">200+ Million</h4>
                                                            <p className="color_dark font_500 mb-0 opacity_50 mt-0 f_18 ">Monthly User</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 mb-sm-0 mb-4">
                                                        <div className="d-flex align-items-center card_bottom_part p-0">
                                                            <Pulse>
                                                                <img className="wh_75" src="/assets/img/feature/secure_round.svg" alt="transaction" />
                                                            </Pulse>
                                                            <h4 className="font_700 text__blue mb-0 border_side">99%</h4>
                                                            <p className="color_dark font_500 mb-0 opacity_50 mt-0 f_18">Safety Gurrenteed</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="d-flex align-items-center card_bottom_part p-0">
                                                            <Pulse>
                                                                <img className="wh_75" src="/assets/img/feature/thumb.svg" alt="transaction" />
                                                            </Pulse>
                                                            <h4 className="font_700 text__blue mb-0 border_side">97%</h4>
                                                            <p className="color_dark font_500 mb-0 opacity_50 mt-0 f_18">Satisfaction Ratio</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* fetures with LycanPay section END */}

                    {/* How it work section Start */}
                    <section>
                        <div className="padd200 overflow-hidden">
                            <div className="main_container">
                                <div className="second_container">
                                    <div className="third_container">
                                        <div className="row">
                                            <div className="title col-lg-7 col-sm-10">
                                                <Fade left>
                                                    <h4 className="heding_secondary color_dark mb-3">How does it work?</h4>
                                                    <p className="color_dark mb_70 f_18 font_500">Register, Complete your documentation and leave the rest for us to provide for you.
                                                    </p>
                                                </Fade>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main_container pe-sm-0">
                                <div className="second_container pe-sm-0 me-sm-0">

                                    <div className="pb_200">
                                        <OwlCarousel className="owl-theme" {...api}>
                                            <div className="thumbnail card_theme" >
                                                <div className="app_card">
                                                    <div className="row bg_gray align-items-lg-start align-items-end padd_70 pb-0 radius_5 h-100">
                                                        <div className="col-lg-5">
                                                            <div className="pb_70">
                                                                <div>
                                                                    <span class="scan_link scan_padd bg_blue">API</span>
                                                                    <h3 className="color_dark">Application Programming Interference: </h3>
                                                                    <p className="color_dark  f_16 font_500">Application Programming Interface Get complete documentation and APIs for simple
                                                                        and efficient integration of LycanPay within your existing systems.  </p>
                                                                </div>


                                                                <a href="#" class="demobtn">Integrate API  <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="w-100 h-100 d-block">
                                                                <img className="w-100 h-100 ms-auto img_bot" src="assets/img/platform/api.png" alt="round" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="thumbnail card_theme">
                                                <div className="app_card">
                                                    <div className="row bg_gray align-items-lg-start align-items-end padd_70 radius_5 h-100">
                                                        <div className="col-lg-6">
                                                            <div className="pb_70 pb-lg-0">
                                                                <div>
                                                                    <span class="scan_link scan_padd bg_blue">Plugin</span>
                                                                    <h3 className="color_dark">Install the Plugin in your Software</h3>
                                                                    <p className="color_dark  f_16 font_500">If APIs don’t work, LycanPay has Plugins prepared for various purposes so you don’t
                                                                        have to go back to the drawing board.
                                                                    </p>
                                                                </div>

                                                                <a href="#" class="demobtn">Install Now  <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-5 offset-lg-1">
                                                            <div className="d-block w-100 h-100">
                                                                <img className="w-100 ms-auto " src="assets/img/platform/plugin.png" alt="round" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="thumbnail card_theme" >
                                                <div className="app_card">
                                                    <div className="row bg_gray align-items-lg-start align-items-end padd_70 radius_5 h-100">
                                                        <div className="col-lg-6">
                                                            <div className="pb_70 pb-lg-0">
                                                                <div>
                                                                    <span class="scan_link scan_padd bg_blue">SDK</span>
                                                                    <h3 className="color_dark">Software development kit:  </h3>
                                                                    <p className="color_dark  f_16 font_500">Multi-purpose SDKs to solve complex requirements wherever applicable. LycanPay has thought of everything.</p>
                                                                </div>

                                                                <a href="#" class="demobtn">Use SDK System <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="d-block w-100 h-100">
                                                                <img className="w_120 ms-auto" src="assets/img/platform/kit.png" alt="round" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* How it work section END */}

                    {/* vendor dashboard section Start */}
                    <section>
                        <div className="padd200  vendor_bg">
                            <div className="main_container">
                                <div className="second_container">
                                    <div className="row gx-xxl-5 align-items-center justify-content-center text-center" >
                                        <div className="col-lg-7 col-sm-10">
                                            <Fade top>
                                                <span className="scan_link bg_white">Vendor Dashboard</span>
                                                <h2 className="heding_body text-white mb-sm-5 mb-3">Easy way to manage your Business with LycanPay</h2>
                                            </Fade>
                                        </div>
                                        <div className="col-12">
                                            <div className="vendoe_img">
                                                <Fade bottom>
                                                    <img className="w-100" src="assets/img/vendor/vandor_dashboard.svg" alt="round" />
                                                </Fade>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="padd200" -speed="2" -position="top" -delay="0.07" -direction="vertical">
                        <div className="second_container">
                            <div className="row align-items-center justify-content-center text-center" >
                                <div className="col-12">
                                    <div className="vendoe_img">
                                        <img className="w-100 " src="assets/img/vendor/vandor_dashboard.png" alt="round" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    </section>
                    {/* vendor dashboard section END */}

                    {/* faq section start */}
                    {/* <section>
                        <div className="padd200 pb_150" >
                            <div className="main_container">
                                <div className="second_container">
                                    <div className="third_container">
                                        <div className="row gx-xxl-5 justify-content-center position-relative">
                                            <div className="col-lg-6 col-sm-6">
                                                <Fade top>
                                                    <h2 className="heding_body color_dark mb-lg-0">Find the answer
                                                        <br className="d-lg-block d-none" />
                                                        you needs</h2>
                                                </Fade>

                                            </div>
                                            <div className="col-lg-6 col-sm-6">
                                                <div>
                                                    <ul className="nav nav-pills nav_pills mb-0" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">General</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Support</button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Payment</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="tab-content tab_content pt_70" id="pills-tabContent">
                                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex={0}>
                                                        <OwlCarousel className="owl-theme pb_90" {...faq}>
                                                            <div className="accordian">
                                                                <h4>How much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                            <div className="accordian">
                                                                <h4>How much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                            <div className="accordian">
                                                                <h4>How much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                            <div className="accordian">
                                                                <h4>How much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                        </OwlCarousel>

                                                        <div className="question">
                                                            <h5><span>General questions</span></h5>
                                                        </div>

                                                    </div>
                                                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                                                        <OwlCarousel className="owl-theme pb_90" {...faq}>
                                                            <div className="accordian">
                                                                <h4>How1 much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                            <div className="accordian">
                                                                <h4>How much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                            <div className="accordian">
                                                                <h4>How much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                            <div className="accordian">
                                                                <h4>How much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                        </OwlCarousel>

                                                        <div className="question">
                                                            <h5><span>General questions</span></h5>
                                                        </div>

                                                    </div>
                                                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex={0}>
                                                        <OwlCarousel className="owl-theme pb_90" {...faq}>
                                                            <div className="accordian">
                                                                <h4>How2 much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                            <div className="accordian">
                                                                <h4>How much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                            <div className="accordian">
                                                                <h4>How much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                            <div className="accordian">
                                                                <h4>How much does LycanPay charge for its payment service?</h4>
                                                                <p>Setting up is free, and we only charge a fee for every successful transaction. There is  no implementation for maintenance fee.</p>
                                                            </div>
                                                        </OwlCarousel>

                                                        <div className="question">
                                                            <h5><span>General questions</span></h5>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {/* faq section end */}

                    {/* footer section and get start section start */}
                    <section>
                        {/* contect section start */}
                        <div>
                            <Get_start />
                        </div>
                        {/* contect section end */}

                        {/* footer section start */}
                        <div className="footer-padd">
                            <Footer />
                        </div>
                        {/* footer section end */}
                    </section>
                    {/* footer section and get start section end */}
                </div>
            </div>

        </>
    );
}


