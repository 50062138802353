import Header from "../Commponet/Header";
import Get_start from "../Commponet/Get_start";
import Footer from "../Commponet/Footer";

import { Link } from 'react-router-dom'
import { useEffect, useRef } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import { } from "react-gsap";
import { Fade, Slide, Zoom } from "react-reveal";
import Pulse from 'react-reveal/Pulse';
import $ from 'jquery';
// import SmoothScroll from "../Commponet/smoothscroll/SmoothScroll";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Scrollbar from "smooth-scrollbar";


export default function Business() {

    const options = {
        loop: true,
        // stagePadding: 100,
        margin: 30,
        nav: true,
        navText: ['<img src="/assets/img/icon/left.svg" />', '<img src="/assets/img/icon/right.svg" />'],
        dots: false,
        autoplayHoverPause: true,
        navClass: ["owl-prev", "owl-next"],
        navSpeed: 1500,
        navElement: "div",
        navContainerClass: "slider_nav",
        lazyLoad: true,
        responsiveClass: true,
        autoplay: false,
        // center: true,
        autoplaySpeed: 1100,
        responsive: {
            0: {
                items: 1.5,
                slideBy: 1,
                margin: 15
            },
            600: {
                items: 1.5,
                slideBy: 1,
                margin: 15
            },
            768: {
                items: 2.5,
                slideBy: 1,
                margin: 20
            },
            900: {
                items: 3.5,
                slideBy: 1,
                margin: 15
            },
            1440: {
                items: 4.5,
                slideBy: 2
            },
            1920: {
                items: 5,
                slideBy: 2
            }
        }
    };

    const api = {
        loop: true,
        // stagePadding: 100,
        mouseDrag: false,
        margin: 30,
        nav: true,
        navText: ['<img src="/assets/img/icon/left.svg" />', '<img src="/assets/img/icon/right.svg" />'],
        dots: false,
        autoplayHoverPause: true,
        navClass: ["owl-prev", "owl-next"],
        navSpeed: 1500,
        navElement: "div",
        navContainerClass: "slider_nav",
        lazyLoad: true,
        responsiveClass: true,
        autoplay: false,
        // center: true,
        autoplaySpeed: 1100,
        responsive: {
            0: {
                items: 1,
                margin: 15
            },
            600: {
                items: 1,
                margin: 15
            },
            768: {
                items: 1.2,
                margin: 20
            },
            1440: {
                items: 1.5,
                slideBy: 1
            },
            1920: {
                items: 1.7,
                slideBy: 1
            }
        }
    };


    const faq = {
        margin: 40,
        nav: true,
        navText: ['<img src="/assets/img/icon/left.svg" />', '<img src="/assets/img/icon/right.svg" />'],
        navClass: ["owl-prev", "owl-next"],
        navSpeed: 1500,
        navElement: "div",
        navContainerClass: "slider_faq",
        lazyLoad: true,
        responsiveClass: true,
        autoplay: false,
        dots: false,
        // center: true,
        responsive: {
            0: {
                items: 1,
                slideBy: 1,
            },
            600: {
                items: 1,
                slideBy: 1,
                margin: 15
            },
            768: {
                items: 2,
                slideBy: 1,
                margin: 20
            },

            1920: {
                items: 2,
                slideBy: 1
            }
        }
    };


    //  progress-top with scroll script
    useEffect(() => {
        window.addEventListener('scroll', () => {
            document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
        }, false);
    }, [])

    const scroller = useRef();

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const scroller = document.querySelector(".scroller");
        const bodyScrollBar = Scrollbar.init(scroller);

        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        bodyScrollBar.addListener(ScrollTrigger.update);

        gsap.to(document.querySelector("#box"), {
            duration: 10,
            scrollTrigger: {
                trigger: document.querySelector("#box"),
                start: "top 10%",
                markers: false,
                scroller: scroller,
                pin: false
            }
        });

        // This part is only neccessary if you're using ScrollTrigger's markers for testing:

    }, []);


    return (
        <>
            <div>
                {/* <div className="progress"></div> */}
                {/* Header section start */}
                <header >
                    <Header />
                </header>
                {/* Header section END */}

                <div className="scroller">

                    {/* Hero section start */}
                    <section>
                        <div className="hero">
                            <div className="main_container position-relative">
                                <div className="row text-sm-start text-center" >
                                    <div className="col-md-6 col-sm-8">
                                        <Fade bottom>
                                            <h1><span className="first_heading ">Make your business crypto-friendly</span></h1>

                                            <p className="small_heding ">
                                                Easy integration of multiple <br className="d-none d-sm-block d-md-none" /> cryptocurrencies to <br className="d-lg-block d-none" /> grow <br className="d-none d-sm-block d-md-none" /> business globally.
                                            </p>
                                            <div>
                                                <a href="https://vendor.lycanpay.com/register" className="start_btn mb-sm-0 mb-5 ">Let’s integrate </a>
                                                {/* <a href="#" className="scan_pay">Scan & Pay <img className="ms-2 tras-aerrow" src="assets/img/icon/errow.svg" alt="scan pay" /></a> */}
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                                <div className="is-inview" >
                                    {/* <div className="hero_img img2">
                                        <img src="assets/img/hero/card_b_2.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img3">
                                        <img src="assets/img/hero/card_b_3.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img4">
                                        <img src="assets/img/hero/card_b_4.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img5">
                                        <img src="assets/img/hero/card_b_5.png" alt="lock" className="w-100 left_card" />
                                    </div>
                                    <div className="hero_img img6">
                                        <img src="assets/img/hero/card_b_6.png" alt="lock" className="w-100 right_card" />
                                    </div>
                                    <div className="hero_img img7">
                                        <img src="assets/img/hero/tether.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img8">
                                        <img src="assets/img/hero/btc.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img9">
                                        <img src="assets/img/hero/eth.png" alt="lock" className="w-100" />
                                    </div>
                                    <div className="hero_img img10 ">
                                        <img src="assets/img/hero/card_top.svg" alt="lock" className="w-100 right_card" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Hero section END */}

                    {/* Demo section start */}
                    {/* <section className="watch_demo position-relative">
                        <div className="main_container position-relative" >
                            <div className="demo row">
                                <div className="col-xxl-4 col-lg-5 col-sm-6">
                                    <img src="assets/img/support/lock.svg" alt="lock" className="mb-3" />
                                    <h2 className="heding_demo mb-3" >Secure and easy-to-use crypto
                                        payment receiver for various businesses.
                                    </h2>
                                    <a href="#" className="demobtn dcf">Watch Demo <img className="ms-2 tras-aerrow" src="assets/img/icon/errow_blue.svg" alt="scan pay" /></a>
                                </div>
                            </div>
                        </div>

                        <div className="hero_img img1">
                            <img src="assets/img/hero/card_b_1.png" alt="lock" className="w-100 h-100" />
                        </div>
                    </section> */}
                    {/* Demo section END */}

                    {/* spport section start */}
                    <section>
                        <div className="support_sec">
                            <div className="main_container" >
                                {/* <div className="row align-items-center justify-content-lg-between justify-content-center">
                                    <div className="col-xxl-auto col-md-4 col-12 mb-xxl-0 mb-md-3 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/support.svg" alt="lock" /> 24x7 Support</h6>                                </div>

                                    <div className="col-xxl-auto col-md-5 col-12 mb-xxl-0 mb-md-3 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/display.svg" alt="lock" /> Borderless</h6>
                                    </div>

                                    <div className="col-xxl-auto col-md-3 col-12 mb-xxl-0 mb-md-3 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/pointer.svg" alt="lock" /> User Friendly</h6>
                                    </div>

                                    <div className="col-xxl-auto col-md-4 col-12 mb-xxl-0 mb-md-0 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/secure.svg" alt="lock" /> Truly Secure</h6>
                                    </div>

                                    <div className="col-xxl-auto col-md-5 col-12 mb-xxl-0 mb-md-0 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/button.svg" alt="lock" /> Customized Payment Button</h6>
                                    </div>

                                    <div className="col-xxl-auto col-md-3 col-12 mb-xxl-0 mb-md-0 mb-3">
                                        <h6 className="support_deck mb-0"><img src="assets/img/support/invoice.svg" alt="lock" /> Invoicing</h6>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </section>
                    {/* support section end */}
                    {/* Quick Crypto In-store section Start */}
                    <section>
                        <div className="main_container padd200 d-sm-block d-none bg_blue_dark pb_220" >
                            <div className="second_container">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-lg-7 col-md-6 store">
                                        <Fade left>
                                            <img src="assets/img/business/pay.svg" alt="store" className="scale-up-ver-top" />
                                        </Fade>

                                    </div>
                                    <div className="col-lg-4 col-md-6 mb-md-0 mb-5">
                                        <span className="button_pg">Crypto Pay</span>
                                        <h2 className="heding_body text-white">Your cryptocurrency
                                            gateway
                                        </h2>
                                        <p className="text-white f_18">Whether your customer is buying shoes, a plane ticket or topping up an account - we provide a guaranteed exchange rate, manage any price volatility and charge a flat 1% transaction fee.</p>
                                        <a href="https://vendor.lycanpay.com/register" className="demobtn text-white">Try it out </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="main_container padd200  d-sm-block d-none" >
                            <div className="second_container">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-lg-4 col-md-6 mb-md-0 mb-5">

                                        <h2 className="heding_body ">The most flexible payments API for cryptocurrencies around
                                        </h2>
                                        <p className=" f_18">Each business has different requirements. Whether you want a plug-and-play gateway for e-commerce, an on-brand checkout, rule-based processing, or need to pull out everything into your payments management system, we have you covered.</p>
                                        <a href="" className="start_btn">Full Api Documentation</a>
                                    </div>

                                    <div className="col-lg-7 col-md-6 store">
                                        <Fade right>
                                            <img src="assets/img/business/api-doc.png" alt="store" className="scale-up-ver-top" />
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* Quick Crypto In-store section END */}
                    {/* Grow with LycanPay section Start */}
                    <section>
                        <div className="main_container padd200 pb_220">
                            <div className="grow">
                                <div className="second_container">
                                    <div className="row text-center justify-content-center mb-100">
                                        <Fade top>
                                            <div className="col-lg-6 col-sm-10" >
                                                <h2 className="heding_body color_dark mb-3 ">Grow with LycanPay</h2>
                                                <p className="text_dark f_18 font_500">LycanPay does not only help you to accept payments,
                                                    we are here to open your business up to the world.</p>
                                            </div>
                                        </Fade>

                                    </div>

                                    <div className="row">
                                        <div className="col-lg-3 col-sm-6 mb-lg-0 mb-4 text-center grow_feture">
                                            <Fade bottom>
                                                <img className="mb-4" src="assets/img/business/card.svg" alt="convenice" />
                                                <h3 className="grow_font color_dark">Accept cryptocurrency
                                                    payments</h3>
                                                <p className="text_dark mb-0 f_16 font_500">Accept cryptocurrency payments from your customers and receive funds straight to your bank account. We settle payments daily in multiple currencies.</p>
                                            </Fade>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 mb-lg-0 mb-4 text-center grow_feture">
                                            <Fade bottom>
                                                <img className="mb-4" src="assets/img/business/grow.svg" alt="convenice" />
                                                <h3 className="grow_font color_dark">Increase international
                                                    conversion rates</h3>
                                                <p className="text_dark mb-0 f_16 font_500">Process sales from customers worldwide without having to rely on or manage local payment methods.</p>
                                            </Fade>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 text-center grow_feture mb-sm-0 mb-4">
                                            <Fade bottom>
                                                <img className="mb-4" src="assets/img/business/in-out.svg" alt="convenice" />
                                                <h3 className="grow_font color_dark">Pay-ins and Pay-outs</h3>
                                                <p className="text_dark mb-0 f_16 font_500">Allow your customers to credit and debit account balances with cryptocurrencies. Send and receive business payments globally in just seconds.</p>
                                            </Fade>
                                        </div>
                                        <div className="col-lg-3 col-sm-6 text-center grow_feture">
                                            <Fade bottom>
                                                <img className="mb-4" src="assets/img/business/no-charge.svg" alt="convenice" />
                                                <h3 className="grow_font color_dark">No. More. Chargebacks.</h3>
                                                <p className="text_dark mb-0 f_16 font_500">Wave goodbye to fraudulent customers and enjoy complete control of your funds. Only you decide when a refund is legitimate.</p>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Grow with LycanPay section END */}

                    <section>
                        <div className="main_container padd200 d-sm-block d-none bg_yellow_light pb_220" >
                            <div className="second_container">
                                <div className="row align-items-center justify-content-between">

                                    <div className="col-lg-4 col-md-6 mb-md-0 mb-5">
                                        <span className="button_pg">Merchant</span>
                                        <h2 className="heding_body">Comprehensive  <br className="d-none d-lg-block" />
                                            merchant back office
                                        </h2>
                                        <p className="f_18">Our merchant back office provides extensive reporting in an intuitive interface. It also makes managing multiple logins for larger payments teams a breeze.
                                        </p>
                                        <p className="f_18">Access transaction histories, sub-accounts, multiple APIs, currency accounts, permission-based accesses and so much more.
                                        </p>
                                    </div>
                                    <div className="col-lg-7 col-md-6 store">
                                        <Fade right>
                                            <img src="assets/img/business/merchant.svg" alt="store" className="scale-up-ver-top" />
                                        </Fade>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Quick Crypto In-store mobile view section Start */}
                    <section>
                        <div className="main_container padd200 d-sm-none d-block" >
                            <div className="second_container">
                                <div className="row" >
                                    <div className="col-lg-4 col-md-6 order-lg-1 order-md-2 mb-md-0 mb-5">
                                        <span className="scan_link">Scan 2 Pay</span>
                                        <h2 className="heding_body color_dark">Quick Crypto <br className="d-none d-lg-block" />
                                            In-store</h2>

                                        <p className="text_dark f_18">The most rapid way to pay in-store and online, whenever and wherever you like. This is built for you to make your crypto payment journey easier as LycanPay makes payments fast with innovative Features.</p>
                                        <a href="#" className="demobtn">Try Now <img className="ms-2 tras-aerrow" src="assets/img/icon/errow-darkblue.svg" alt="scan pay" /></a>
                                    </div>
                                    <div className="col-lg-7 col-md-6 offset-lg-1 order-lg-2 order-md-1 store">
                                        <Fade right>
                                            <img src="assets/img/store.svg" alt="store" className="scale-up-ver-top" />
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Quick Crypto In-store mobile view section END */}






                    {/* faq section start */}
                    <section>
                        <div className="padd200 pb_150" >
                            <div className="main_container">
                                <div className="second_container">
                                    <div className="third_container">
                                        <div className="row gx-xxl-5 justify-content-center position-relative">
                                            <div className="col-lg-12">
                                                <Fade top>
                                                    <h2 className="heding_body color_dark mb-lg-0">Get started</h2>
                                                    <p className="small_heding">We’ve been processing cryptocurrency payments for businesses since 2014. We’re tech-driven and customer focused. Ready to get started? Sign up now or get in touch with us through the form.</p>
                                                </Fade>

                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>First Name</label>
                                                <input type="text" className="form-control" placeholder="First Name" />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control" placeholder="Last Name" />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Job Title</label>
                                                <input type="text" className="form-control" placeholder="Job Title" />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Company</label>
                                                <input type="text" className="form-control" placeholder="Company Name" />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Email</label>
                                                <input type="email" className="form-control" placeholder="Email" />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Phone</label>
                                                <input type="text" className="form-control" placeholder="Phone" />
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <label>Message</label>
                                                <textarea type="text" className="form-control" rows={5} />
                                            </div>
                                            <div className="col-12 mt-4">
                                                <button className="start_btn">Send message</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* faq section end */}

                    {/* footer section and get start section start */}
                    <section>
                        {/* contect section start */}
                        <div>
                            <Get_start />
                        </div>
                        {/* contect section end */}

                        {/* footer section start */}
                        <div className="footer-padd">
                            <Footer />
                        </div>
                        {/* footer section end */}
                    </section>
                    {/* footer section and get start section end */}
                </div>
            </div>

        </>
    );
}


