import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Page/Home';

import ContactUs from './Page/ContactUs';
import Faqs from './Page/Faqs';
import Business from './Page/Business';
import Pricing from './Page/Pricing';



function App() {



  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/business" element={<Business />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="faqs" element={<Faqs />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
