import { Link } from "react-router-dom";



export default function Header() {
    return (
        <>
            <div className="main_container">
                <nav className="navbar navbar-expand-lg position-relative">
                    <div className="container-fluid px-0">
                        <Link className="navbar-brand" to={'/'}>
                            <img src="assets/img/logo/logo.svg" alt="logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <img src="assets/img/icon/menu.svg" alt="menu icon" />
                        </button>
                        <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link active" to={'/business'}>Business</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={'/'}>Merchant</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={'/pricing'}>Pricing</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={'/faqs'}>FAQs</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={'/contact'}>Contact</Link>
                                </li>
                            </ul>
                            <form className="d-flex align-items-center">
                                <a href={'https://vendor.lycanpay.com/login'} className="login_link me-4">Login</a>
                                <a href={'https://vendor.lycanpay.com/register'} className="button_pg">Create an account</a>
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}