import React from 'react'

export default function LearnOne() {
    return (
        <>
            <div>
                <h3 className='f_45 font_700 color_dark pb-2'>
                    Support and Troubleshooting
                </h3>
                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q12: How do I contact LycanPay support?</h5>
                    <p className='f_18 color_dark font_500 opacity_50 border-bottom pb-3'>You can contact LycanPay support through the support section on our website or
                        by emailing support@lycanpay.com. Our team is available to assist with any issues or
                        questions you may have.

                    </p>
                </div>
                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q13: What should I do if I forget my password? </h5>
                    <p className='f_18 color_dark font_500 opacity_50 border-bottom pb-3'> If you forget your password, click on the "Forgot Password" link on the login page.
                        You will be prompted to enter your email address to receive instructions on how to
                        reset your password.
                    </p>
                </div>
                <div>
                    <h5 className='f_20 mb-2 font_600 color_dark '>Q14: How do I enable two-factor authentication (2FA)?</h5>
                    <p className='f_18 color_dark font_500 opacity_50 border-bottom pb-3'>To enable 2FA, go to the "Profile" section of your dashboard, and follow the
                        instructions in the 2FA setup process. This adds an extra layer of security to your
                        account [oai_citation:9,Lycanpay - Live Version.pdf](file-service://file0WrYeCBOd2x0FD47cSkpfwxt).
                    </p>
                </div>
            </div>
        </>
    )
}
