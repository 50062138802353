import React from 'react'
import { Link } from 'react-router-dom'
import { Fade } from "react-reveal";
import Reveal from 'react-reveal/Reveal';


export default function Get_start() {
    return (
        <>
            <div className="contact-area ">
                <div className="main_container">
                    <div className="second_container">
                        <div className="row">
                            <div className="col-lg-8 col-sm-10">
                                <div className="contant_business">
                                <Reveal effect="fadeInUp">
                                    <h3 className="heding_body f_45 text-white">Are you ready to boom your business 10x faster than before</h3>
                                    </Reveal>
                                </div>
                                <form className="row">
                                    <div className="col-sm-8 mb-3 mb-sm-0 contant_form">
                                        <input type="email" className="form-control text-white get_start" id="inputPassword2" placeholder="Enter your email address" />
                                    </div>
                                    <div className="col-sm-4 ps-2 ps-sm-0 p-0">
                                        <Link className="start_btn btn_white" to={'/'}>Get Started</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
