import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from "react";
import Footer from '../Commponet/Footer';
import Get_start from '../Commponet/Get_start';
import Header from '../Commponet/Header';
import Pulse from 'react-reveal/Pulse';
import Jump from 'react-reveal/Jump';
// import SmoothScroll from "../Commponet/smoothscroll/SmoothScroll";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Scrollbar from "smooth-scrollbar";




export default function ContactUs() {

    
    //  progress-top with scroll script
    useEffect(() => {
        window.addEventListener('scroll', () => {
            document.body.style.setProperty('--scroll',window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
            }, false);
    }, [])


    const scroller = useRef();

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const scroller = document.querySelector(".scroller");
        const bodyScrollBar = Scrollbar.init(scroller);

        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        bodyScrollBar.addListener(ScrollTrigger.update);

        gsap.to(document.querySelector("#box"), {
            duration: 10,
            scrollTrigger: {
                trigger: document.querySelector("#box"),
                start: "top 10%",
                markers: false,
                scroller: scroller,
                pin: false
            }
        });

        // This part is only neccessary if you're using ScrollTrigger's markers for testing:

    }, []);

    return (
        <>
         {/* <div className="progress"></div> */}
        
            <div className="scroller" >
                {/* Header section start */}
                <header>
                    <Header />
                </header>
                {/* Header section END */}


                {/* contact hero section start */}
                <section className='contact_hero'></section>
                {/* contact hero section end */}

                {/* fetures with LycanPay section Start */}
                <section>
                    <div className="ptb_50 mt_250">
                        <div className="main_container">
                            <div className="second_container">
                                <div className="row align-items-end justify-content-between">
                                    <div className="col-lg-6 col-sm-5 text-sm-start text-center">
                                        <Jump>
                                            <span className='mb-2 f_18 font_500 d-block'>Support</span>
                                            <h1 className="color_dark mobile_white mb-3 "><span className="first_heading">
                                                Let's Talk
                                            </span></h1>
                                            <p className="text_dark f_18 font_500 mobile_white mb-sm-0 mb-5">Get in touch and let us know how we can help you to make your payment journey more easy and secure.</p>
                                        </Jump>
                                       
                                    </div>
                                    <div className="col-xxl-4 offset-xxl-2 col-xl-5  offset-xl-1 col-lg-5 col-sm-7 ">
                                        <Pulse>
                                            <div className="card contact_card ">
                                                <h6 className='mb-0 pb_70'>
                                                    <span> <img className="wh_25 me-2" src="/assets/img/conatct/contact_mail.svg" alt="transaction" /></span>
                                                    Contact Us
                                                </h6>
                                                <div className="">
                                                    <input type="email" className="form-control" placeholder="First Name" />
                                                    <input type="email" className="form-control" placeholder="Last Name" />
                                                    <input type="email" className="form-control" placeholder="Work Email" />
                                                    <select className="form-select" aria-label="Default select example">
                                                        <option selected>Country of incorporation</option>
                                                        <option value={1}>One</option>
                                                        <option value={2}>Two</option>
                                                        <option value={3}>Three</option>
                                                    </select>
                                                    <textarea class="form-control" id="exampleFormControlTextarea1" placeholder='Message' rows="2"></textarea>
                                                    <Link to={'/'} className="start_btn mt-4">Send Message</Link>
                                                </div>
                                            </div>
                                        </Pulse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* fetures with LycanPay section END */}

                {/* contect section start */}
                <section>
                    <div>
                        <Get_start />
                    </div>
                    {/* contect section end */}

                    {/* footer section start */}
                    <div className="footer-padd">
                        <Footer />
                    </div>
                </section>
                {/* footer section end */}
            </div>
            
        </>
    )
}
